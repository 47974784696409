import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function SwitchUserGuide() {
  return (
    <Layout
      keywords="Switch integration user"
      title="Change your integration user"
      description="Documentation on how to change the integration user for IrisAgent"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/switch-user-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Switch User Guide</h2>
            <ol>
              <li>
                <Link to="/irisagent-docs/">Home</Link>
              </li>
              <li>Switch User Guide</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>How to change integration user?</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    The integration user of IrisAgent is used as an author for private notes and sending replies to customers. By default, the user who first gave access of your ticketing system to IrisAgent is used as an integration user.

                    <br/><br/>
                    Sometimes, when another user of your organization signs as a new on our dashboard and gives access to the ticketing system, this changes the integration user to this new user.
                    <br/><br/><br/>
                    <h2>
                    Steps to change the integration user
                    </h2>
                    <br/>
                    <ol>
                    <li>
                    <a href="mailto:contact@irisagent.com" target="_blank">Send us</a> the email address of the user who should be the integration user.
                    </li>
                    <li>
                    Once you hear back from us, ask the new user to sign in to the <a href="https://web.irisagent.com" target="_blank">IrisAgent dashboard</a> and give access to the ticketing system. For instance, if you use Zendesk, then click on "Connect with Zendesk" after signing in.
                    </li>
                    </ol>

                    <br/>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}
